<template>
    <!-- Section - Start -->
    <section id="section-start" data-anchor="start" class="section section-home-basic fullheight dark bg-dark mouse-parallax" data-scheme="dark" data-local-scroll>
        <!-- BG Image -->
        <div class="bg-slideshow">
            <div class="slide" v-for="background in start.backgrounds" v-bind:key="background.id">
                <div class="bg-image-container zooming"><img :src="require('@/assets/imgs/' + background.name)" alt="" /></div>
            </div>
        </div>

        <!-- Section Content -->
        <div class="section-content">
            <div class="container-wide" data-local-scroll>
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <!-- <img src="@/assets/imgs/logo-light.svg" class="home-logo animated" data-animation="fadeIn" alt="" /> -->
                        <h1 class="display-4 mb-1">{{ start.name }}</h1>
                        <p class="lead text-lg text-muted mb-5">
                            <span class="typing">{{ start.title }}</span
                            ><a href="#resume" class="btn-go btn-go-right show-after-typing ml-4"><i class="fa fa-angle-right"></i></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Lines -->
        <div class="lines-container mouse-parallax-item" data-parallax-speed="140">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </section>
</template>

<script>
import { NetServices } from '@/network/netservices'
import Pages from '@/utils/pages'

export default {
    data() {
        return { start: {} }
    },

    async beforeCreate() {
        const start = await NetServices.get('start')
        this.start = start.data
        this.$store.commit('initialized', Pages.START)
    }
}
</script>
