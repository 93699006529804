import Pages from '@/utils/pages'
import { createStore } from 'vuex'

export default createStore({
    state: {
        uninitialized: [Pages.START, Pages.ABOUT, Pages.SKILLS, Pages.EXPERIENCES, Pages.LANGUAGES, Pages.HOBBIES, Pages.CLIENTS, Pages.PROJECTS, Pages.SERVICES, Pages.TESTIMONIALS, Pages.CONTACT]
    },
    getters: {},
    mutations: {
        initialized(state, page) {
            state.uninitialized.splice(state.uninitialized.indexOf(page), 1)
            if (state.uninitialized.length !== 0) { return }
            const event = new Event('initialized')
            window.dispatchEvent(event)
        }
    },
    actions: {},
    modules: {}
})
